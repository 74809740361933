import { FormInstance } from "antd";
import BigNumber from "bignumber.js";
import _ from "lodash";
import moment from "moment";
import { MESSAGES } from "src/constants/messages";
import { getLatLngByAddress, isWithinHongKong } from "./maps";

export const validateRequired = async (value: string, message: string) => {
  if (!value?.trim()) {
    throw new Error(message);
  }
};

export const validateRequired2 = (value: string, message: string) => {
  if (!value?.trim()) {
    throw new Error(message);
  }
};

export const validateCharacter = (value: string, condition = 100, message: string) => {
  if (value?.trim()?.length > condition) {
    throw new Error(message);
  }
};

export const validateProductText = async (value: string, condition = 255, message: string) => {
  validateCharacter(value, condition, message);
};

export const validateProductName = async (_: any, value: string) => {
  const maxLength = 70;
  validateRequired2(value, "Please enter Product Name");
  validateCharacter(value, maxLength, `Product name should not exceed 70 characters`);
};

export const validateSeaFoodDescription = async (_: any, value: string) => {
  const maxLength = 255;
  validateCharacter(value, maxLength, MESSAGES.MC29);
};

export const validateSeaRegionDescription = async (_: any, value: string) => {
  const maxLength = 255;
  validateCharacter(value, maxLength, MESSAGES.MC30);
};

export const validateDescription = async (_: any, value: string) => {
  const maxLength = 255;
  validateCharacter(value, maxLength, MESSAGES.MC31);
};

export const validatePackSealDate = async (value: string, form: FormInstance) => {
  const valueDateCaught = form.getFieldValue("dateCaught");
  const caughtDate = valueDateCaught ? moment(form.getFieldValue("dateCaught")) : null;
  const packSealDate = moment(value);
  if (caughtDate && packSealDate.isValid() && packSealDate.isBefore(caughtDate)) {
    throw new Error("Pack and Seal Date should not be anterior to Caught date");
  }
};

export const validateSendCollectionDate = async (value: string, form: FormInstance) => {
  const valuePackAndSealDate = form.getFieldValue("packAndSealDate");
  const valueDateCaught = form.getFieldValue("dateCaught");
  const caughtDate = valueDateCaught ? moment(valueDateCaught) : null;
  const sealDate = valuePackAndSealDate ? moment(valuePackAndSealDate) : null;
  const collectionDate = moment(value);
  if (
    (collectionDate.isValid() && sealDate && collectionDate.isBefore(sealDate)) ||
    (caughtDate && collectionDate.isBefore(caughtDate))
  ) {
    throw new Error(
      "Send & collection date should not be anterior to Pack & seal Date and Caught date",
    );
  }
};

export const validateHumidity = async (value: number) => {
  isMoreThanValue(value, 100, MESSAGES.MC24);
};

export const validateWeight = async (value: number) => {
  const stringValue = value?.toString().replace(".", "");

  if (value && stringValue?.length > 10) {
    throw new Error(MESSAGES.MC21);
  }
  validateIsZero(value, MESSAGES.MC28);
};

export const validateIsZero = (value: number | string, message: string) => {
  if (new BigNumber(value).isZero()) {
    throw new Error(message);
  }
};

const isMoreThanValue = (value1: string | number, value2: string | number, message: string) => {
  if (value1 && value2 && new BigNumber(value1).gt(new BigNumber(value2))) {
    throw new Error(message);
  }
};

export const validateNumberQrCode = async (_: any, value: number | string) => {
  validateIsZero(value, "The minimum number of QR codes that can be created is 1");
  isMoreThanValue(value, 10, "The maximum number of QR codes that can be created at once is 10");
};

export const validateDigit = async (
  value: number | string,
  numberDigits: number,
  message: string,
) => {
  const stringValue = value?.toString().replace(".", "");

  if (stringValue?.length > numberDigits) {
    throw new Error(message);
  }
};

export const validateInRange = async (
  value: number,
  range1: number,
  range2: number,
  message: string,
) => {
  if (value && !_.inRange(value, range1, range2)) {
    throw new Error(message);
  }
};

export const handleValidatePickLocation = async (address: string, label: string) => {
  if (!address) {
    throw new Error(`Please select ${label}`);
  }
  const location = await getLatLngByAddress(address);
  if (!location) {
    throw new Error("Region information unavailable, please select another location");
  }

  if (!isWithinHongKong(location.lat, location.lng)) {
    throw new Error("Selected location needs to be within the Hong Kong map");
  }
};

export const validatePassword = async (rule: any, value: string) => {
  if (!value) {
    return;
  }

  if (value.length < 6 || value.length > 20) {
    return Promise.reject("Password should be 6-20 characters");
  }

  // Regular expressions to check for character types
  const uppercaseRegex = /[A-Z]/;
  const lowercaseRegex = /[a-z]/;
  const digitRegex = /[0-9]/;
  const specialCharRegex = /[!@#$%^&*()_+[\]{};:'"<>,.?/~\\-]/;

  const missingTypes: string[] = [];
  if (!uppercaseRegex.test(value)) {
    missingTypes.push("Uppercase letters");
  }
  if (!lowercaseRegex.test(value)) {
    missingTypes.push("Lowercase letters");
  }
  if (!digitRegex.test(value)) {
    missingTypes.push("Numerical digits");
  }
  if (!specialCharRegex.test(value)) {
    missingTypes.push("Special characters");
  }

  if (missingTypes.length > 0) {
    return Promise.reject(`Password must contain: ${missingTypes.join(", ")}`);
  }

  return Promise.resolve();
};

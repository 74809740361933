import { useEffect, useState } from "react";
import {
  TxChartForHomePage,
  GenderChartForHomePage,
  GenderChartByCategory,
  CountryChartForHomePage,
  CountryChartByCategory,
} from "src/interfaces/chart";
import { ChartService } from "src/services/chart-service";
import {
  TxChartForHomePageParams,
  GenderChartForHomePageParams,
  GenderChartByCategoryParams,
} from "src/services/params-type";

type UseChartDataResponse<T> = {
  data: T[] | null;
  loading: boolean;
  error: Error | null;
};

export function useChart<T>(fetchDataFn: () => Promise<{ data: T[] }>): UseChartDataResponse<T> {
  const [data, setData] = useState<T[] | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await fetchDataFn();
        setData(result.data);
      } catch (err: any) {
        setError(err);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { data, loading, error };
}

export function useTxChartForHomePageData(
  params?: TxChartForHomePageParams,
): UseChartDataResponse<TxChartForHomePage> {
  const service = new ChartService();
  return useChart(() => service.getTxChartForHomePage(params));
}

export function useGenderChartForHomePageData(
  params?: GenderChartForHomePageParams,
): UseChartDataResponse<GenderChartForHomePage> {
  const service = new ChartService();
  return useChart(() => service.getGenderChartForHomePage(params));
}

export function useCountryChartForHomePageData(): UseChartDataResponse<CountryChartForHomePage> {
  const service = new ChartService();
  return useChart(() => service.getCountryChartForHomePage());
}

export function useGenderChartByCategoryData(
  params?: GenderChartByCategoryParams,
  categoryId?: number,
): UseChartDataResponse<GenderChartByCategory> {
  const service = new ChartService();
  return useChart(() => service.getGenderChartByCategory(params, categoryId));
}

export function useCountryChartByCategoryData(
  categoryId?: number,
): UseChartDataResponse<CountryChartByCategory> {
  const service = new ChartService();
  return useChart(() => service.getCountryChartByCategory(categoryId));
}

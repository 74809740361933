import { ServiceBase } from "./core/service-base";
import { CategoryParams } from "./params-type";

export class CategoryService extends ServiceBase {
  createCategory = async (body: any) => {
    return this.post("/categories/create-category", body);
  };

  getCategories = async (params?: CategoryParams) => {
    return this.get("/categories", params);
  };

  getCategoryDetail = async (id: string) => {
    return this.get(`/categories/${id}`);
  };
  updateCategory = async (id: string, body: any) => {
    return this.put(`/categories/${id}`, body);
  };
  showHide = async (id: string) => {
    return this.put(`/categories/${id}/show-type`, {});
  };
  deleteCategory = async (id: Category["id"]) => {
    return this.delete("/admins/categories", id);
  };
  getCategoriesTree = async (params?: CategoryParams): Promise<CategoryItem[]> => {
    const response = await this.get("/categories/tree", params);
    return response.data.data;
  };

  getCategoriesById = async (id: string | number): Promise<CategoryItem> => {
    const response = await this.get(`/categories/${id}`);
    return response.data;
  };
}

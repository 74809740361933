import axios from 'axios';
import { KEY_GOOGLE_MAP } from 'src/constants/env';
import { GeocodeApiResponse } from 'src/interfaces/maps';
interface Params {
  address?: string;
  latlng?: any;
}
export class GoogleMapLocation {
  getLocation = async (params: Params): Promise<GeocodeApiResponse> => {
    try {
      const response = await axios.get('https://maps.googleapis.com/maps/api/geocode/json', {
        params: {
          ...params,
          key: KEY_GOOGLE_MAP,
        },
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  };
}

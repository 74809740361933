import React from "react";
import { Footer } from "antd/lib/layout/layout";
import { AppStoreLogo, PlayStoreLogo, TierhubLogo } from "src/assets/icons";

const ListContact = [
  {
    label: "Contact Us",
    link: "",
  },
  {
    label: "About",
    link: "",
  },
  {
    label: "Terms",
    link: "",
  },
  {
    label: "Privacy",
    link: "",
  },
  {
    label: "Contact",
    link: "",
  },
];
const FooterLayout = () => {
  return (
    <Footer className="footer">
      <div className="footer__contacts">
        <div className="logo">
          <img src={TierhubLogo} alt="" />
        </div>
        <div className="border"></div>
        <div className="contact">
          <ul>
            {ListContact.map((contact: any, index:number) => {
              return (
                <li key={index}>
                  <a target="_blank" href={contact?.link}>
                    {contact?.label}
                  </a>
                </li>
              );
            })}
          </ul>
          <p>CompanyName @ 202X. All rights reserved.</p>
        </div>
      </div>
      <div className="footer__social">
        <a href="#">
          <img src={AppStoreLogo} alt="" />
        </a>
        <a href="#">
          <img src={PlayStoreLogo} alt="" />
        </a>
      </div>
    </Footer>
  );
};

export default FooterLayout;

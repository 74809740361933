export const commonRegexInput = (decimals?: number) => String.raw`^\d{0,100}(\.\d{0,2})?$`;
export const commonRegexInputExceptMinus = (decimals?: number) => /^-?\d{0,100}(\.\d{0,2})?$/;
export const commonRegexTemplateInput = (decimals?: number) => String.raw`^~?\d{0,20}(\.\d{0,6})?$`;

export const handleKeyDownRegexNumber = (e: any, allowedKeys: number[]) => {
  const keyCode = e.keyCode || e.which;
  if (allowedKeys.includes(keyCode)) {
    return;
  }
  if (!numericRegexWithDecimal.test(e.key) || exceptNumberSymbol.includes(e.key)) {
    e.preventDefault();
  }
};

export const numericRegexWithDecimal = /^[0-9~]*\.?[0-9]*$/;
export const keycodeAllowNumber = [8, 37, 39, 46];

export const exceptNumberSymbol = ["e", "E", "+", "-"];
export const RegexDecimal = /^~?\d+(\.\d{1,6})?$/;
export const Regex2Decimal = /^\d{0,100}(\.\d{0,2})?$/;
export const exceptNumberSymbolExceptMinus = ["e", "E", "+"];
export const exceptPhoneNumberSymbol = ["e", "E", "+", "-", "."];
export const exceptPhoneNumberSymbolPattern = new RegExp(`[^${exceptPhoneNumberSymbol.join("")}]`);
export const RegexSpecialCharacters = /^(?!.*\s\s)[^_.~!@#$%^&*()+:;"'?\/<>]+$/gm;
export const numericRegex = /^[0-9]+$/;

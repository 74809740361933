import { useLoadScript } from "@react-google-maps/api";
import { Button, Card, Checkbox, Col, Form, Input, Row, Select, Switch } from "antd";
import { useWatch } from "antd/lib/form/Form";
import { useCallback, useEffect, useRef, useState } from "react";
import { useHistory, useParams } from "react-router";
import { AddFieldIcon, MinusCircleIcon } from "src/assets/icons";
import ButtonContained from "src/components/08.buttons/ButtonContainer";
import ButtonOutLined from "src/components/08.buttons/ButtonOutLined";
import { ImageInput } from "src/components/16.image-input";
import { toastMS } from "src/components/20.toast-message";
import { Spinner } from "src/components/spinner";
import { IMAGE_TYPE_ALLOW } from "src/constants";
import { KEY_GOOGLE_MAP } from "src/constants/env";
import { PATHS } from "src/constants/paths";
import {
  commonRegexInput,
  commonRegexTemplateInput,
  handleKeyDownRegexNumber,
  keycodeAllowNumber,
} from "src/constants/regex";
import { CategoryService } from "src/services/category-service";
import { CategoryParams } from "src/services/params-type";
import { UploadServices } from "src/services/upload-service";
import InputLocation from "../component/InputLocation";
import { SelectedProps } from "../interface";
import "../styles.scss";
import PickLocationModal from "./PickLocationModal";

const CreateCategoryContainer = (props: any) => {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: KEY_GOOGLE_MAP,
    libraries: ["marker", "places"],
    language: "en",
    region: "HK",
  });
  if (!isLoaded) return <Spinner />;
  else return <CreateCategory />;
};

const CreateCategory = () => {
  const history = useHistory();
  const categoryService = new CategoryService();
  const uploadService = new UploadServices();
  const [listCategory, setListCategory] = useState([]);
  const [isOpenLocationModal, setOpenLocationModal] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(1);
  const [form] = Form.useForm();
  const { id } = useParams<{ id: string }>();
  const [logoImg, setLogoImg] = useState("");
  const [logoError, setLogoError] = useState("");
  const [isShowed, setIsShowed] = useState(true);
  const [loading, setLoading] = useState(false);
  const [checkedHK, setCheckedHK] = useState(false);
  const [checkedAU, setCheckedAU] = useState(true);
  const [selected, setSelected] = useState<SelectedProps[]>([]);
  const parentIdField = useWatch("parentId", form);
  const inputRef = useRef<any>("");

  const listNutrition = [
    {
      label: "High in omega-3 fatty acids",
      value: 1,
    },
    { label: "Low in fat", value: 2 },
    { label: "High in protein", value: 3 },
  ];

  const listOrigin = [
    {
      label: "Fresh water",
      value: 1,
    },
    {
      label: "Salt water",
      value: 2,
    },
    {
      label: "Wild",
      value: 3,
    },
    {
      label: "Farmed",
      value: 4,
    },
  ];

  const handleCancel = () => {
    setOpenLocationModal(false);
  };

  const handlePickLocation = (lat: number, lng: number, result: any) => {
    const data = form.getFieldValue("locations");
    data[selectedIndex] = {
      ...data[selectedIndex],
      location: result?.results[0].formatted_address,
      longitude: lng,
      latitude: lat,
    };
    const formatDataSelected = data.map((item: any) => ({
      lat: item?.latitude,
      lng: item?.longitude,
    }));
    setSelected(formatDataSelected);
    form.setFieldValue("locations", data);
    form.validateFields([["locations", selectedIndex, "location"]]);
    // handleCancel();
  };

  const onFinish = async (value: any) => {
    console.log("value", value);

    if (logoError) {
      return;
    }
    setLoading(true);
    try {
      let logo = "";
      if (logoImg !== value.logo) {
        const formData = new FormData();
        formData.append("file", value.logo);
        const resLogo = await uploadService.uploadDocument(formData);
        logo = resLogo?.data?.url;
      } else {
        logo = logoImg;
      }
      const locations = value?.locations
        ?.filter((val: any) => val?.location)
        ?.map((item: any) => {
          return {
            latitude: item?.latitude,
            longitude: item?.longitude,
            avg_price: +item?.avgPrice,
            address: item?.location,
            currency: "HKD",
          };
        });
      const body = {
        ...value,
        isShowed: isShowed,
        displayInAuMap: checkedAU,
        displayInHkMap: checkedHK,
        logo,
        locations: locations || [],
      };
      if (id) {
        await categoryService.updateCategory(id, body);
        toastMS("success", "Category edited");
      } else {
        await categoryService.createCategory(body);
        toastMS("success", "Category created");
      }
      history.push(PATHS.categoryManagement());
    } catch (error: any) {
      const { response } = error;
      console.log("Error", error);
      if (response?.data?.errorCode === "UPLOAD_FAILED") {
        toastMS("error", "Upload image failed");
      } else {
        toastMS("error", "Action failed due to unexpected error");
      }
    }
    setLoading(false);
  };

  const fetchCategory = async () => {
    try {
      const result = await categoryService.getCategoryDetail(id);
      if (result.data) {
        const locations = result.data.categoryLocations;
        form.setFieldsValue(result.data);
        const locationsData = locations.map((item: any, index: number) => ({
          key: index,
          location: item.address,
          avgPrice: Number(item.avgPrice),
          longitude: item.longitude,
          latitude: item.latitude,
        }));
        setLogoImg(result.data.logo);
        setIsShowed(result.data.isShowed);
        setCheckedAU(result.data.displayInAuMap);
        setCheckedHK(result.data.displayInHkMap);
        form.setFieldValue("locations", locationsData);
      }
    } catch (error: any) {
      console.log({ error });
      toastMS("error", error?.data);
    }
  };

  useEffect(() => {
    if (id) {
      fetchCategory();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const handleResetForm = () => {
    if (id) {
      form.resetFields([
        "locations",
        "logo",
        "name",
        "nutritionCategory",
        "origin",
        "calories",
        "protein",
        "totalFat",
        "saturatedFat",
        "monounsaturatedFat",
        "polyunsaturatedFat",
        "omega3FattyAcids",
        "cholesterol",
        "sodium",
        "potassium",
        "totalCarbohydrates",
        "fiber",
        "sugars",
        "vitaminD",
        "vitaminB12",
        "selenium",
      ]);
    } else {
      form.resetFields();
    }
    setLogoImg("");
    setIsShowed(true);
    setCheckedHK(false);
    setCheckedAU(true);
  };

  const handleChangeInput = useCallback(
    (e: any, name: string) => {
      const value = e.target.value;
      if (value) {
        if (new RegExp(commonRegexTemplateInput()).test(value)) {
          inputRef.current = value;
          form.setFieldValue(name, value);
        } else {
          form.setFieldValue(name, inputRef.current);
        }
      }
    },
    [form],
  );

  const validateNutrition = async (_: any, value: any, label: string, name?: any) => {
    const value1 = form.getFieldValue(name);
    if (value1) {
      const digitRegex = /\d/g;
      const matches = value1.match(digitRegex);
      if (matches?.length > 10) {
        return Promise.reject(`${label} should not exceed 10 digits`);
      }
    }
    return Promise.resolve();
  };
  const onFinishFailed = (error: any) => {
    console.log("error", error);
    const { errorFields } = error;
    const fieldNameError = errorFields[0]?.name?.join("_");
    if (fieldNameError === "logo") {
      window.scrollTo(0,0);
    }
    document.getElementById(errorFields[0]?.name?.join("_"))?.focus();
  };
  const fetchCategories = async () => {
    const params: CategoryParams = {
      hasParent: false,
      isShowed: true,
      direction: "DESC",
      sortBy: "updatedAt",
    };
    const res = await categoryService.getCategories(params);
    const { data } = res;
    const filteredData = data.data
      .filter((item: any) => !item.parentId)
      .map((category: any) => {
        return {
          label: category.name,
          value: category.id,
        };
      });
    setListCategory(filteredData);
  };
  useEffect(() => {
    if (!checkedHK || !parentIdField) {
      form.resetFields(["locations"]);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkedHK, parentIdField]);

  useEffect(() => {
    fetchCategories();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {loading && <Spinner />}
      <Form
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        className="create-category"
        initialValues={{
          displayInAuMap: false,
          displayInHkMap: false,
          isShowed: true,
        }}
        form={form}
        layout="vertical"
      >
        <Card>
          <Row gutter={[16, 24]} justify="space-between">
            <Col span={4}>
              <Form.Item
                label="Category Image"
                name="logo"
                rules={[{ required: true, message: "Please select Category Image" }]}
                {...(logoError && {
                  validateStatus: "error",
                  help: logoError,
                })}
              >
                <ImageInput
                  style={{ width: 150, height: 150 }}
                  name="logo"
                  fileTypes={[IMAGE_TYPE_ALLOW.PNG, IMAGE_TYPE_ALLOW.JPG]}
                  url={logoImg}
                  setImageError={setLogoError}
                />
              </Form.Item>
              <span>
                Size for optimal display
                <br /> quality: 500 x 500px.
              </span>
            </Col>
            <Col span={20}>
              <Row gutter={[16, 24]}>
                <Col span={12}>
                  <Form.Item
                    name="name"
                    label="Name"
                    rules={[
                      { required: true, message: "Please enter Category Name" },
                      { max: 70, message: "Address must be between 1-70 characters" },
                    ]}
                  >
                    <Input size="large" placeholder="Category Name" />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item name="parentId" label="Parent Category">
                    <Select
                      disabled={!!id}
                      size="large"
                      showSearch
                      allowClear
                      placeholder="Parent category"
                      options={listCategory}
                      filterOption={(input, option: any) => {
                        const _input = input?.toLowerCase();
                        const label = option?.label?.toLowerCase();
                        return (label ?? "").includes(_input);
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[16, 24]}>
                <Col span={12}>
                  <Form.Item
                    name="nutritionCategory"
                    label="Nutrition Category"
                    rules={[
                      {
                        required: true,
                        message: "Please select Nutrition Category",
                      },
                    ]}
                  >
                    <Select
                      size="large"
                      showSearch
                      placeholder="Nutrition category"
                      options={listNutrition}
                      filterOption={(input, option: any) => {
                        const _input = input?.toLowerCase();
                        const label = option?.label?.toLowerCase();
                        return (label ?? "").includes(_input);
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="origin"
                    label="Origin"
                    rules={[{ required: true, message: "Please select Origin" }]}
                  >
                    <Select
                      size="large"
                      showSearch
                      placeholder="Origin"
                      options={listOrigin}
                      filterOption={(input, option: any) => {
                        const _input = input?.toLowerCase();
                        const label = option?.label?.toLowerCase();
                        return (label ?? "").includes(_input);
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <h3>Nutrition detail in 100g</h3>
              <Row gutter={[16, 24]}>
                <Col span={8}>
                  <Form.Item
                    name="calories"
                    label="Calories"
                    rules={[
                      {
                        validator: (e, value) =>
                          validateNutrition(e, value, "Calories", "calories"),
                      },
                    ]}
                  >
                    <Input
                      onKeyDown={(e) => handleKeyDownRegexNumber(e, keycodeAllowNumber)}
                      onChange={(e) => handleChangeInput(e, "calories")}
                      size="large"
                      placeholder="Calories"
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    name="protein"
                    label="Protein (g)"
                    rules={[
                      {
                        validator: (e, value) => validateNutrition(e, value, "Protein", "protein"),
                      },
                    ]}
                  >
                    <Input
                      onChange={(e) => handleChangeInput(e, "protein")}
                      onKeyDown={(e) => handleKeyDownRegexNumber(e, keycodeAllowNumber)}
                      size="large"
                      placeholder="Protein (g)"
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    name="totalFat"
                    label="Total Fat (g)"
                    rules={[
                      {
                        validator: (e, value) =>
                          validateNutrition(e, value, "Total Fat", "totalFat"),
                      },
                    ]}
                  >
                    <Input
                      onChange={(e) => handleChangeInput(e, "totalFat")}
                      onKeyDown={(e) => handleKeyDownRegexNumber(e, keycodeAllowNumber)}
                      size="large"
                      placeholder="Total Fat (g)"
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={[16, 24]}>
                <Col span={8}>
                  <Form.Item
                    name="saturatedFat"
                    label="Saturated Fat (g)"
                    rules={[
                      {
                        validator: (e, value) =>
                          validateNutrition(e, value, "Saturated Fat", "saturatedFat"),
                      },
                    ]}
                  >
                    <Input
                      onChange={(e) => handleChangeInput(e, "saturatedFat")}
                      onKeyDown={(e) => handleKeyDownRegexNumber(e, keycodeAllowNumber)}
                      size="large"
                      placeholder="Saturated Fat (g)"
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    name="monounsaturatedFat"
                    label="Monounsaturated Fat (g)"
                    rules={[
                      {
                        validator: (e, value) =>
                          validateNutrition(e, value, "Monounsaturated Fat", "monounsaturatedFat"),
                      },
                    ]}
                  >
                    <Input
                      onChange={(e) => handleChangeInput(e, "monounsaturatedFat")}
                      onKeyDown={(e) => handleKeyDownRegexNumber(e, keycodeAllowNumber)}
                      size="large"
                      placeholder="Monounsaturated Fat (g)"
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    name="polyunsaturatedFat"
                    label="Polyunsaturated Fat (g)"
                    rules={[
                      {
                        validator: (e, value) =>
                          validateNutrition(e, value, "Polyunsaturated Fat", "polyunsaturatedFat"),
                      },
                    ]}
                  >
                    <Input
                      onChange={(e) => handleChangeInput(e, "polyunsaturatedFat")}
                      onKeyDown={(e) => handleKeyDownRegexNumber(e, keycodeAllowNumber)}
                      size="large"
                      placeholder="Polyunsaturated Fat (g)"
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={[16, 24]}>
                <Col span={8}>
                  <Form.Item
                    name="omega3FattyAcids"
                    label="Omega-3 Fatty Acids (g)"
                    rules={[
                      {
                        validator: (e, value) =>
                          validateNutrition(e, value, "Omega-3 Fatty Acids", "omega3FattyAcids"),
                      },
                    ]}
                  >
                    <Input
                      onChange={(e) => handleChangeInput(e, "omega3FattyAcids")}
                      onKeyDown={(e) => handleKeyDownRegexNumber(e, keycodeAllowNumber)}
                      size="large"
                      placeholder="Omega-3 Fatty Acids (g)"
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    name="cholesterol"
                    label="Cholesterol (g)"
                    rules={[
                      {
                        validator: (e, value) =>
                          validateNutrition(e, value, "Cholesterol", "cholesterol"),
                      },
                    ]}
                  >
                    <Input
                      onChange={(e) => handleChangeInput(e, "cholesterol")}
                      onKeyDown={(e) => handleKeyDownRegexNumber(e, keycodeAllowNumber)}
                      size="large"
                      placeholder="Cholesterol (g)"
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    name="sodium"
                    label="Sodium (g)"
                    rules={[
                      { validator: (e, value) => validateNutrition(e, value, "Sodium", "sodium") },
                    ]}
                  >
                    <Input
                      onChange={(e) => handleChangeInput(e, "sodium")}
                      onKeyDown={(e) => handleKeyDownRegexNumber(e, keycodeAllowNumber)}
                      size="large"
                      placeholder="Sodium (g)"
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={[16, 24]}>
                <Col span={8}>
                  <Form.Item
                    name="potassium"
                    label="Potassium (g)"
                    rules={[
                      {
                        validator: (e, value) =>
                          validateNutrition(e, value, "Potassium", "potassium"),
                      },
                    ]}
                  >
                    <Input
                      onChange={(e) => handleChangeInput(e, "potassium")}
                      onKeyDown={(e) => handleKeyDownRegexNumber(e, keycodeAllowNumber)}
                      size="large"
                      placeholder="Potassium (g)"
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    name="totalCarbohydrates"
                    label="Total Carbohydrates (g)"
                    rules={[
                      {
                        validator: (e, value) =>
                          validateNutrition(e, value, "Total Carbohydrates", "totalCarbohydrates"),
                      },
                    ]}
                  >
                    <Input
                      onChange={(e) => handleChangeInput(e, "totalCarbohydrates")}
                      onKeyDown={(e) => handleKeyDownRegexNumber(e, keycodeAllowNumber)}
                      size="large"
                      placeholder="Total Carbohydrates (g)"
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    name="fiber"
                    label="Fiber (g)"
                    rules={[
                      { validator: (e, value) => validateNutrition(e, value, "Fiber", "fiber") },
                    ]}
                  >
                    <Input
                      onChange={(e) => handleChangeInput(e, "fiber")}
                      onKeyDown={(e) => handleKeyDownRegexNumber(e, keycodeAllowNumber)}
                      size="large"
                      placeholder="Fiber (g)"
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={[16, 24]}>
                <Col span={8}>
                  <Form.Item
                    name="sugars"
                    label="Sugars (g)"
                    rules={[
                      { validator: (e, value) => validateNutrition(e, value, "Sugars", "sugars") },
                    ]}
                  >
                    <Input
                      onChange={(e) => handleChangeInput(e, "sugars")}
                      onKeyDown={(e) => handleKeyDownRegexNumber(e, keycodeAllowNumber)}
                      size="large"
                      placeholder="Sugars (g)"
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    name="vitaminD"
                    label="Vitamin D"
                    rules={[
                      {
                        validator: (e, value) =>
                          validateNutrition(e, value, "Vitamin D", "vitaminD"),
                      },
                    ]}
                  >
                    <Input
                      onChange={(e) => handleChangeInput(e, "vitaminD")}
                      onKeyDown={(e) => handleKeyDownRegexNumber(e, keycodeAllowNumber)}
                      size="large"
                      placeholder="Vitamin D"
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    name="vitaminB12"
                    label="Vitamin B12"
                    rules={[
                      {
                        validator: (e, value) =>
                          validateNutrition(e, value, "Vitamin B12", "vitaminB12"),
                      },
                    ]}
                  >
                    <Input
                      onChange={(e) => handleChangeInput(e, "vitaminB12")}
                      onKeyDown={(e) => handleKeyDownRegexNumber(e, keycodeAllowNumber)}
                      size="large"
                      placeholder="Vitamin B12"
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={[16, 24]}>
                <Col span={8}>
                  <Form.Item
                    name="selenium"
                    label="Selenium (mcg)"
                    rules={[
                      {
                        validator: (e, value) =>
                          validateNutrition(e, value, "Selenium", "selenium"),
                      },
                    ]}
                  >
                    <Input
                      onChange={(e) => handleChangeInput(e, "selenium")}
                      onKeyDown={(e) => handleKeyDownRegexNumber(e, keycodeAllowNumber)}
                      size="large"
                      placeholder="Selenium (mcg)"
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Form.Item className="flex-no-wrap" name="displayInAuMap">
                <Checkbox checked={checkedAU} onChange={() => setCheckedAU(!checkedAU)}>
                  Display in Australia map filter
                </Checkbox>
              </Form.Item>
              <Form.Item className="flex-no-wrap" name="displayInHkMap">
                <Checkbox checked={checkedHK} onChange={() => setCheckedHK(!checkedHK)}>
                  Display in Hong Kong map filter
                </Checkbox>
              </Form.Item>
              {!!parentIdField && checkedHK && (
                <Form.List name="locations" initialValue={[""]}>
                  {(fields, { add, remove }) => (
                    <>
                      {fields.map(({ key, name, ...restField }, index) => (
                        <div key={key}>
                          <Row gutter={5}>
                            <Col span={20}>
                              <InputLocation
                                name={[index, "location"]}
                                form={form}
                                isRequired={!!parentIdField && checkedHK ? true : false}
                                disabled={!!parentIdField && checkedHK ? false : true}
                                index={index}
                                setSelected={setSelected}
                                selected={selected}
                              />
                            </Col>
                            <Col span={3} style={{ textAlign: "right" }}>
                              <ButtonOutLined
                                className="button-get-location"
                                onClick={() => {
                                  setOpenLocationModal(true);
                                  setSelectedIndex(index);
                                }}
                                disabled={!!parentIdField && checkedHK ? false : true}
                              >
                                Pick Location on Map
                              </ButtonOutLined>
                            </Col>
                          </Row>
                          <Row gutter={5} key={key} align="middle">
                            <Col span={20}>
                              <Form.Item
                                name={[index, "avgPrice"]}
                                label="Avg Price in Hong Kong"
                                // style={{ width: "100%" }}

                                rules={[
                                  {
                                    required: !!parentIdField && checkedHK ? true : false,
                                    message: "Please enter price",
                                  },
                                ]}
                              >
                                <Input
                                  onChange={(e) => {
                                    const value: any = e.target.value;
                                    if (new RegExp(commonRegexInput()).test(value)) {
                                      inputRef.current = value;
                                      form.setFieldValue(["locations", index, "avgPrice"], value);
                                    } else {
                                      form.setFieldValue(
                                        ["locations", index, "avgPrice"],
                                        inputRef.current,
                                      );
                                    }
                                  }}
                                  type="number"
                                  size="large"
                                  placeholder="Avg Price in Hong Kong"
                                  disabled={!!parentIdField && checkedHK ? false : true}
                                />
                              </Form.Item>
                            </Col>
                            {fields?.length > 1 ? (
                              <Col span={3} style={{ textAlign: "right" }}>
                                <Button
                                  className="create-category__btn-remove"
                                  onClick={() => {
                                    remove(name);
                                  }}
                                >
                                  <img src={MinusCircleIcon} alt="" />
                                </Button>
                              </Col>
                            ) : null}
                          </Row>
                        </div>
                      ))}
                      <Col span={8} className="add-location">
                        {!!parentIdField && checkedHK && (
                          <img
                            src={AddFieldIcon}
                            alt=""
                            onClick={() => {
                              add();
                            }}
                          />
                        )}
                      </Col>
                    </>
                  )}
                </Form.List>
              )}

              <Form.Item
                className="flex-no-wrap"
                name="isShowed"
                label={
                  <>
                    Status<span style={{ color: "#777E90" }}>(Optional)</span>
                  </>
                }
                valuePropName="checked"
              >
                <span className="flex-no-wrap__text">Hide</span>{" "}
                <Switch checked={isShowed} onChange={() => setIsShowed(!isShowed)} />{" "}
                <span className="flex-no-wrap__text">Show</span>
              </Form.Item>
            </Col>
          </Row>
        </Card>
        <Row justify="end" style={{ gap: "20px", margin: "20px 0px" }}>
          <ButtonOutLined style={{ minWidth: "200px" }} onClick={handleResetForm}>
            Reset
          </ButtonOutLined>
          <ButtonContained style={{ minWidth: "200px" }} htmlType="submit">
            {id ? "Save" : "Create new category"}
          </ButtonContained>
        </Row>
      </Form>
      <PickLocationModal
        open={isOpenLocationModal}
        handleCancel={handleCancel}
        handlePickLocation={handlePickLocation}
        selected={selected}
        selectedIndex={selectedIndex}
      />
    </>
  );
};

export default CreateCategoryContainer;

import loadable, { DefaultComponent } from "@loadable/component";
import React from "react";
import { Redirect, Route, Switch } from "react-router";
import { useHistory } from "react-router-dom";
import { PATHS } from "src/constants/paths";
import { hasStorageJwtToken } from "src/helpers/storage";
import CategoryDetail from "src/pages/CategoryManagement/category-detail";
import CreateCategory from "src/pages/CategoryManagement/create-category";
import HomePageAdmin from "src/pages/home";
import { ExchangePageLoading, MarketPageLoading } from "src/pages/loadings";
import PasswordChanged from "src/pages/login/PasswordChanged";
import { PrivateRoute } from "./components/PrivateRoute";

const LoadingByTemplate: React.FC = () => {
  const history = useHistory();

  if (history.location.pathname.includes(PATHS.default())) {
    return <ExchangePageLoading />;
  }
  return <MarketPageLoading />;
};

function loadableWFallback(loadFn: (props: {}) => Promise<DefaultComponent<{}>>) {
  return loadable(loadFn, {
    fallback: <LoadingByTemplate />,
  });
}

const NotFound = loadableWFallback(() => import("./components/PageState"));
const LoginPage = loadableWFallback(() => import("src/pages/login"));
const MemberAdmin = loadableWFallback(() => import("src/pages/MemberAdministration"));
const TemplateAdmin = loadableWFallback(() => import("src/pages/Template"));
const CreateTemplate = loadableWFallback(() => import("src/pages/Template/CreateTemplate"));

const AdvertisementAdmin = loadableWFallback(() => import("src/pages/Advertisement"));
const CategoryAdmin = loadableWFallback(() => import("src/pages/CategoryManagement"));
const AdminManagement = loadableWFallback(() => import("src/pages/AdminManagement"));
const CreateAdmin = loadableWFallback(() => import("src/pages/AdminManagement/CreateAdmin"));
const ApproveAdmin = loadableWFallback(() => import("src/pages/GroupAdmin/ApproveGroup"));
const GroupDetail = loadableWFallback(() => import("src/pages/GroupAdmin/DetailGroup"));
const GroupAdmin = loadableWFallback(() => import("src/pages/GroupAdmin/ListGroup"));
const ChangePassword = loadableWFallback(() => import("src/pages/home/ChangePassword"));

const ProductManagement = loadableWFallback(() => import("src/pages/product-management"));
const ForgotPassword = loadableWFallback(() => import("src/pages/forgot-password"));
const ForgotSuccess = loadableWFallback(() => import("src/pages/forgot-success"));
const ResetPassword = loadableWFallback(() => import("src/pages/reset-password"));
const ProductDetail = loadableWFallback(
  () => import("src/pages/product-management/product-detail"),
);

const Routes: React.FC = () => {
  return (
    <Switch>
      <Route
        exact
        path="/"
        render={() =>
          hasStorageJwtToken() ? <Redirect to={PATHS.default()} /> : <Redirect to={PATHS.login()} />
        }
      />
      <PrivateRoute exact path="/home" component={HomePageAdmin} />
      <Route exact path={PATHS.passwordChanged()} component={PasswordChanged} />
      <Route
        exact
        path={PATHS.login()}
        render={() => (hasStorageJwtToken() ? <Redirect to={PATHS.default()} /> : <LoginPage />)}
      />
      <Route
        exact
        path={PATHS.forgotPassword()}
        render={() =>
          hasStorageJwtToken() ? <Redirect to={PATHS.default()} /> : <ForgotPassword />
        }
      />
      <Route
        exact
        path={PATHS.forgotSuccess()}
        render={() =>
          hasStorageJwtToken() ? <Redirect to={PATHS.default()} /> : <ForgotSuccess />
        }
      />
      <Route
        exact
        path={PATHS.resetPassword()}
        render={() =>
          hasStorageJwtToken() ? <Redirect to={PATHS.default()} /> : <ResetPassword />
        }
      />
      <PrivateRoute exact path={PATHS.groupApproveDetail()} component={GroupDetail} />
      <PrivateRoute exact path={PATHS.groupListDetail()} component={GroupDetail} />
      <PrivateRoute exact path={PATHS.approveGroup()} component={ApproveAdmin} />
      <PrivateRoute exact path={PATHS.groupList()} component={GroupAdmin} />
      <PrivateRoute exact path={PATHS.adminManagement()} component={AdminManagement} />
      <PrivateRoute exact path={PATHS.createAdmin()} component={CreateAdmin} />
      <PrivateRoute exact path={PATHS.categoryManagement()} component={CategoryAdmin} />
      <PrivateRoute exact path={PATHS.memberAdministration()} component={MemberAdmin} />
      <PrivateRoute exact path={PATHS.template()} component={TemplateAdmin} />
      <PrivateRoute exact path={PATHS.createTemplate()} component={CreateTemplate} />
      <PrivateRoute exact path={PATHS.templateDetail()} component={CreateTemplate} />
      <PrivateRoute exact path={PATHS.advertisement()} component={AdvertisementAdmin} />
      <PrivateRoute exact path={PATHS.changePassword()} component={ChangePassword} />
      <PrivateRoute exact path={`${PATHS.productDetail()}/:id`} component={ProductDetail} />
      <PrivateRoute exact path={PATHS.productManagement()} component={ProductManagement} />
      <PrivateRoute exact path={PATHS.createCategory()} component={CreateCategory} />
      <PrivateRoute exact path={PATHS.categoryDetail()} component={CategoryDetail} />
      <PrivateRoute exact path={PATHS.updateCategory()} component={CreateCategory} />
      <Route path="*" component={NotFound} />
    </Switch>
  );
};

export default Routes;

export const PATHS = {
  default: () => "/home",
  notification: () => "/notification",
  categoryDetail: () => "/category-management/:id",
  login: () => "/login",
  productManagement: () => "/product-management",
  productDetail: () => "/product-detail",
  configMenu: () => `/config-menu/:index`,
  forgotPassword: () => "/forgot-password",
  forgotSuccess: () => "/forgot-success",
  resetPassword: () => "/auth/reset-password",
  lpCashOut: () => "/lp-management",
  registration: () => "/registration",
  categoryView: () => "/category-view/:id",
  listQRCode: () => "/qr-code",
  groupInfo: () => "/group-information",
  adminManagement: () => "/admin-management",
  createAdmin: () => "/admin-management/create-new-admin",
  memberAdministration: () => "/member-administration",
  categoryManagement: () => "/category-management",
  approveGroup: () => "/approve-group",
  groupList: () => "/group-list",
  groupApproveDetail: () => "/approve-group/:id",
  groupListDetail: () => "/group-list/:id",
  updateProduct: () => "/product/update",
  changePassword: () => "/change-password",
  passwordChanged: () => "/password-changed",
  template: () => "/template",
  createTemplate: () => "/template/create-template",
  templateDetail: () => "/template/edit-template/:id",
  advertisement: () => "/advertisement",
  setting: () => "/setting",
  createCategory: () => "/category-management/create-new-category",
  updateCategory: () => "/category-management/edit-category/:id",
};

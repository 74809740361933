import {
  DashboardOutlined,
  ProfileOutlined,
  TableOutlined
} from "@ant-design/icons";
import { Category, Group, Home, NotificationMenu, Product, Setting, Tag, Template, User } from "src/assets/icons";

export const MENUS_KEY = {
  HOME: 'home',
  PRODUCT_MANAGEMENT: 'product-management',
  QR_CODE_LIST: 'qr-code',
  GROUP_MANAGEMENT: 'group-management',
  GROUP_ADMINISTRATION: 'group-administration',
  ADMIN_MANAGEMENT: 'admin-management',
  MEMBER_ADMINISTRATION: 'member-administration',
  CATEGORY_MANAGEMENT: 'category-management',
  TEMPLATE: 'template',
  ADVERTISEMENT: 'advertisement',
  NOTIFICATION: 'notification'
};

export const MENUS_ITEM = {
  HOME_ADMIN: {
    key: "home",
    label: "Home",
    level: 1,
    icon: <Home/>
  },
  GROUP_ADMINISTRATION: { 
    key: "group-administration",
    label: "Group Administration",
    level: 1,
    icon: <Group />,
    children: [
      { key: "approve-group", label: "Approve Group", path: "approve-group", level: 2 },
      { key: "group-list", label: "Group list", path: "group-list", level: 2 },
    ],
  },
  ADMIN_MANAGEMENT: {
    key: "admin-management",
    label: "Admin Management",
    level: 1,
    icon: <Setting />
  },
  TEMPLATE: {
    key: "template",
    label: "Template",
    level: 1,
    icon: <Template />
  },
  ADVERTISEMENT: {
    key: "advertisement",
    label: "Advertisement",
    level: 1,
    icon: <Tag />
  },
  NOTIFICATION: {
    key: "notification",
    label: "Notification",
    level: 1,
    icon: <NotificationMenu />
  },
  PRODUCT_MANAGEMENT: {
    key: "product-management",
    label: "Product Management",
    level: 1,
    icon: <Product />
  },
  MEMBER_ADMINISTRATION: {
    key: "member-administration",
    label: "Member Administration",
    level: 1,
    icon: <User />
  },
  CATEGORY_MANAGEMENT: {
    key: "category-management",
    label: "Category Management",
    level: 1,
    icon: <Category />
  },
};

export const DEEP_MENUS = [
  {
    name: "Home",
    key: "home",
    children: [],
  },
  {
    name: "Group Administration",
    key: "group-administration",
    breadcrumb: [""],
    children: [
      { key: "approve-group", label: "Approve Group", path: "approve-group", level: 2 },
      { key: "group-list", label: "Group list", path: "group-list", level: 2 },
    ],
  },
  {
    name: "Admin Management",
    key: "admin-management",
    children: [],
  },
  {
    name: "Create Management",
    key: "create-management",
    children: [],
  },
  {
    name: "Member Administration",
    key: "member-administration",
    children: [],
  },
  {
    name: "Category Management",
    key: "category-management",
    children: [],
  },
  {
    name: "Create New Category",
    key: "create-new-category",
    children: [],
  },
  {
    name: "Edit Category",
    key: "edit-category",
    children: [],
  },
  {
    name: "Create Template",
    key: "create-template",
    children: [],
  },
  {
    name: "Template",
    key: "template",
    children: [],
  },
  {
    name: "Edit Template",
    key: "edit-template",
    children: [],
  },
  {
    name: "Advertisement",
    key: "advertisement",
    children: [],
  },
  {
    name: "Notification",
    key: "notification",
    children: [],
  },
  {
    name: "Product Management",
    key: "product-management",
    children: [],
  },
  
];

import BigNumber from "bignumber.js";

export const IMAGE_TYPE_ALLOW = {
  JPG: "image/jpg",
  JPEG: "image/jpeg",
  PNG: "image/png",
};
export const LatLngHK = {
  minLat: 22.1435,
  maxLat: 22.4965,
  minLng: 113.8259,
  maxLng: 114.4214,
};
export const DATE_FORMAT_PRODUCT = "YYYY/MM/DD HH:mm";
export const LIMIT = 10;
export const GET_ALL = 10000000000;

export const IMAGE_SIZE_ALLOW = {
  fiveMB: 5 * 1024 * 1024,
  tenMB: 10 * 1024 * 1024,
};
export const DEFAULT_MODAL_WIDTH = 528; // unit pixcel

export enum GROUPS_STATUS {
  PENDING = 0,
  APPROVABLE = 1,
  APPROVED = 2,
  REJECTED = 3,
  REVOKED = 4,
  OWNER_REVOKED = 5,
}
export const getStatus = (status: number) => {
  if (status === GROUPS_STATUS.PENDING) {
    return "Pending";
  } else if (status === GROUPS_STATUS.APPROVABLE) {
    return "Available";
  } else if (status === GROUPS_STATUS.APPROVED) {
    return "Available";
  } else if (status === GROUPS_STATUS.REJECTED) {
    return "Rejected";
  } else if (status === GROUPS_STATUS.OWNER_REVOKED) {
    return "Owner Revoked";
  } else if (status === GROUPS_STATUS.REVOKED) {
    return "Revoked";
  }
};

export enum ORDER_STATUS {
  PENDING = 0,
  CONFIRMED = 1,
  DELIVERING = 2,
  DELIVERED = 3,
  CANCELLED = 4,
  REFUNDING = 5,
  REFUNDED = 6,
  SUCCEEDED = 7,
  SETTLED = 8,
  WAITING_FOR_PAYMENT = 9,
  REJECTED = 10,
  EXPIRED = 11,
  ON_GOING = 12,
  COMPLETED = 13,
  USER_PICKED_UP = 14,
  PREPARED = 15,
  PAID = 16,
  REFUND_REJECTED = 17,
}

export const getOrderStatus = (status: number) => {
  if (status === ORDER_STATUS.PENDING) {
    return "Pending";
  } else if (status === ORDER_STATUS.CONFIRMED) {
    return "Confirmed";
  } else if (status === ORDER_STATUS.DELIVERING) {
    return "Delivering";
  } else if (status === ORDER_STATUS.DELIVERED) {
    return "Delivered";
  } else if (status === ORDER_STATUS.CANCELLED) {
    return "Cancelled";
  } else if (status === ORDER_STATUS.REFUNDING) {
    return "Refunding";
  } else if (status === ORDER_STATUS.REFUNDED) {
    return "Refunded";
  } else if (status === ORDER_STATUS.SUCCEEDED) {
    return "Succeeded";
  } else if (status === ORDER_STATUS.SETTLED) {
    return "Settled";
  } else if (status === ORDER_STATUS.WAITING_FOR_PAYMENT) {
    return "Waiting for payment";
  } else if (status === ORDER_STATUS.REFUNDED) {
    return "Refunded";
  } else if (status === ORDER_STATUS.EXPIRED) {
    return "Expired";
  } else if (status === ORDER_STATUS.ON_GOING) {
    return "On going";
  } else if (status === ORDER_STATUS.COMPLETED) {
    return "Completed";
  } else if (status === ORDER_STATUS.USER_PICKED_UP) {
    return "User picked up";
  } else if (status === ORDER_STATUS.PREPARED) {
    return "Prepared";
  } else if (status === ORDER_STATUS.REFUND_REJECTED) {
    return "Refund refused";
  } else {
    return "Paid";
  }
};

export const format2Digit = (value: number | string) => {
  return new BigNumber(value || 0).toFormat(2, 1);
};

export enum MAIN_TAG {
  hot = "Hot",
  best_seller = "Best Seller",
  new = "New Dish",
}

export enum PAGE_STATE {
  NOT_FOUND = "404",
  SUCCESS = "success",
}

export enum NOT_FOUND_MSG {
  PAGE_BROKEN = "May be bigfoot has broken this page. Come back to the homepage",
  APPROVE_CAMERA = "You need to approve browser to use your camera",
  PERMISSION = "You have no permission to view/update information for this product",
  INVALID_QRCODE = "Invalid QR Code",
  TRANSACTION_FAILED = "Transaction to record to Blockchain failed!",
  APPROVAL_TIER_HUB = "You may need approval from TIER HUB team",
  APPROVAL_TIER_GROUP = "You may need approval from your TIER Group Manager",
}

export enum SUCCESS_MSG {
  TRANSACTION_SUCCESS = "Your record is stored on Blockchain",
}

export const formatCountdown = (seconds: number) => {
  return `${Math.floor(seconds / 60)
    .toString()
    .padStart(2, "0")}:${(seconds % 60).toString().padStart(2, "0")}`;
};

export enum EPRODUCT_GRADE {
  A = "A",
  B = "B",
  C = "C",
}

export enum EPACKAGE_FORM {
  WHOLE = 1,
  FILLETED,
  STEAKS,
  CANNED,
}

export enum EPRODUCT_MEDIA_TYPE {
  IMAGE = 1,
  VIDEO,
}

export const EPRODUCT_GRADE_OPTIONS = [
  {
    value: EPRODUCT_GRADE.A,
    label: EPRODUCT_GRADE.A,
  },
  {
    value: EPRODUCT_GRADE.B,
    label: EPRODUCT_GRADE.B,
  },
  {
    value: EPRODUCT_GRADE.C,
    label: EPRODUCT_GRADE.C,
  },
];

export const EPACKAGE_FORM_OPTIONS = [
  { value: EPACKAGE_FORM.WHOLE, label: "Whole" },
  { value: EPACKAGE_FORM.FILLETED, label: "Filleted" },
  { value: EPACKAGE_FORM.STEAKS, label: "Steaks" },
  { value: EPACKAGE_FORM.CANNED, label: "Canned" },
];

export enum EPREPARATION_FORM {
  RAW = 1,
  COOKED,
  SMOKED,
  LIVE,
  FROZEN,
  DRIED,
  CANNED,
  PICKLED,
}

export const PREPARATION_OPTIONS = [
  {
    value: EPREPARATION_FORM.RAW,
    label: "Raw",
  },
  {
    value: EPREPARATION_FORM.COOKED,
    label: "Cooked",
  },
  {
    value: EPREPARATION_FORM.SMOKED,
    label: "Smoked",
  },
  {
    value: EPREPARATION_FORM.LIVE,
    label: "Live",
  },
  {
    value: EPREPARATION_FORM.FROZEN,
    label: "Frozen",
  },
  {
    value: EPREPARATION_FORM.DRIED,
    label: "Dried",
  },
  {
    value: EPREPARATION_FORM.CANNED,
    label: "Canned",
  },
  {
    value: EPREPARATION_FORM.PICKLED,
    label: "Pickled",
  },
];

export enum ETemplateDataType {
  TEXT = "TEXT",
  NUMBER = "NUMBER",
  DATE_TIME = "DATE_TIME",
  SINGLE_SELECT = "SINGLE_SELECT",
  MULTI_SELECT = "MULTI_SELECT",
}

export enum EProductStatus {
  NORMAL = 1,
  REPORTED,
}

export enum ETraceabilityStatus {
  PENDING_SUBMIT = 1,
  SUBMITTING,
  SUBMITTED,
  CONFIRMED,
  FAILED,
}

import { ImgTier } from "src/assets/icons";
import { GROUPS_STATUS } from ".";

export const STEPS_LOGIN = {
  NOT_LOGIN: 0,
  WAITING_SEND_CODE: 1,
  CODE_SENDED: 2,
  EXISTED_ACCOUNT: 3,
};

export const REGISTRATION = {
  SELECT_TIER: 0,
  REGISTRATION_TIER: 1,
};

export const STEPS_REGISTRATION = {
  STEP_1: 0,
  STEP_2: 1,
  STEP_3: 2,
  STEP_4: 3,
  STEP_5: 4,
};

export enum USER_STATUS {
  PENDING = 0,
  APPROVABLE = 1,
  APPROVED = 2,
  REJECTED = 3,
  REVOKED = 4,
}

export enum TIER {
  HARVESTER = 1,
  LOGISTIC_PARTNER = 2,
  FACTORY = 3,
  DISTRIBUTOR = 4,
  CUSTOMER = 5,
}

export enum EUserStatus {
  PENDING = 0,
  APPROVABLE,
  APPROVED,
  REJECTED,
  REVOKED,
}

export const TierOptions = [
  { value: "", label: "All" },
  { value: TIER.HARVESTER, label: "Farmer/Fisher/Diver" },
  { value: TIER.LOGISTIC_PARTNER, label: "Logistics Partner" },
  { value: TIER.FACTORY, label: "Factory" },
  { value: TIER.DISTRIBUTOR, label: "Distributor" },
  { value: TIER.CUSTOMER, label: "Customer" },
];

export const TierOptionsGroup = [
  { value: "", label: "All" },
  { value: TIER.HARVESTER, label: "Farmer/Fisher/Diver" },
  { value: TIER.LOGISTIC_PARTNER, label: "Logistics Partner" },
  { value: TIER.FACTORY, label: "Factory" },
  { value: TIER.DISTRIBUTOR, label: "Distributor" },
];

export const getTier = (number: number | string) => {
  if (number === TIER.HARVESTER) {
    return "Farmer/Fisher/Diver";
  } else if (number === TIER.LOGISTIC_PARTNER) {
    return "Logistics Partner";
  } else if (number === TIER.FACTORY) {
    return "Factory";
  } else if (number === TIER.DISTRIBUTOR) {
    return "Distributor";
  } else if (number === TIER.CUSTOMER) {
    return "Customer";
  } else {
    return "TBD";
  }
};

export enum USER_GENDER {
  MALE = 0,
  FEMALE = 1,
  OTHER = 2,
}

export const GenderOptions = [
  { value: null, label: "All" },
  { value: USER_GENDER.MALE, label: "Male" },
  { value: USER_GENDER.FEMALE, label: "Female" },
  { value: USER_GENDER.OTHER, label: "Other" },
];

export const StatusOptions = [
  { value: 0, label: "All" },
  { value: GROUPS_STATUS.APPROVED, label: "Available" },
  { value: GROUPS_STATUS.OWNER_REVOKED, label: "Owner Revoked" },
  { value: GROUPS_STATUS.REVOKED, label: "Revoked" },
];
export enum ERROR_CODE {
  WRONG_EMAIL_OR_PASS = "WRONG_EMAIL_OR_PASS",
}

export const renderErrorMsg = (errorCode: string) => {
  switch (errorCode) {
    case "WRONG_EMAIL_OR_PASS":
      return "Incorrect email or password";
    case "NOT_SIGNUP_WITH_PASSWORD":
      return "This account doesn't sign up with password";
    default:
      return "";
  }
};

export const getGender = (number: number | string) => {
  if (number === USER_GENDER.MALE) {
    return "Male";
  } else if (number === USER_GENDER.FEMALE) {
    return "Female";
  } else if (number === USER_GENDER.OTHER) {
    return "Other";
  } else {
    return "TBD";
  }
};

export enum LICENSE_TYPE {
  IMAGE = 1,
  DOCS = 2,
}

export enum USER_TYPE {
  USER = 1,
  ADMIN = 2,
  SUPER_ADMIN = 3,
}

export const tiers = [
  {
    key: TIER.HARVESTER,
    tier: TIER.HARVESTER,
    name: "Farmer / Fisher / Diver",
    image: ImgTier,
  },
  {
    key: TIER.LOGISTIC_PARTNER,
    tier: TIER.LOGISTIC_PARTNER,
    name: "Logistic partner",
    image: ImgTier,
  },
  {
    key: TIER.FACTORY,
    tier: TIER.FACTORY,
    name: "Factory",
    image: ImgTier,
  },
  {
    key: TIER.DISTRIBUTOR,
    tier: TIER.DISTRIBUTOR,
    name: "Distributor",
    image: ImgTier,
  },
  {
    key: TIER.CUSTOMER,
    tier: TIER.CUSTOMER,
    name: "Customer",
    image: ImgTier,
  },
];

export enum TEMPLATE_DATA_TYPE {
  TEXT = "TEXT",
  NUMBER = "NUMBER",
  DATE_TIME = "DATE_TIME",
  SINGLE_SELECT = "SINGLE_SELECT",
  MULTI_SELECT = "MULTI_SELECT",
}

export const EMAIL_PATTERN = /^[a-zA-Z0-9.!#$%&'*+\-/=?^_`{|}]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
export const PHONE_PATTERN = /^[0-9]{4,15}$/;
export const BSB_PATTERN = /^[0-9]{6}$/;
// export const PASSWORD_PATTERN = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@~`$#^!%*?&()_+{}\[\]:;<>,.?])[A-Za-z\d@~`$!%*?&]+$/;
export const PASSWORD_PATTERN =
  /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[!@#$%^&*()_+{}\[\]:;<>,.?/~\\`"'-=])[A-Za-z\d!@#$%^&*()_+{}\[\]:;<>,.?/~\\`"'-=]+$/;
export const ACC_NUMBER_PATTERN = /^[0-9]{1,30}$/;
export enum VERIFY_EMAIL {
  INVALIDATED_CODE = "1",
  NOT_EXISTED_EMAIL = "2",
}

import { Button, ButtonProps } from 'antd';
import React from 'react';
import './styles/button-outlined.scss';

interface IButtonOutLinedProps extends ButtonProps {
  className?: 'outlined-remove' | 'default-h40' | string;
}

const ButtonOutLined: React.FC<IButtonOutLinedProps> = (props: IButtonOutLinedProps) => {
  const { children, className } = props;
  return (
    <Button
      className={`${className ? className : 'outlined-default'} `}
      {...props}
      onMouseDown={(e) => e.preventDefault()}>
      {children}
    </Button>
  );
};

export default ButtonOutLined;

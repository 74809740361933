import { Modal } from "antd";
import SimpleMap from "src/pages/product-detail/maps";
import "../styles.scss";

interface PickLocationModalProps {
  open: boolean;
  handleCancel: () => void;
  handlePickLocation?: (lat: number, lng: number, result: any) => void;
  selected?: { lat: number; lng: number }[];
  selectedIndex?: number;
}

const PickLocationModal = (props: PickLocationModalProps) => {
  const { open, handleCancel, handlePickLocation, selectedIndex, selected } = props;
  return (
    <Modal
      open={open}
      title="Pick Location"
      footer={null}
      onCancel={handleCancel}
      className="pick-location"
      maskClosable={false}
      centered
      width={720}
    >
      <div style={{ width: "100%", height: "100%" }}>
        <SimpleMap
          handlePickLocation={handlePickLocation}
          selectedIndex={selectedIndex}
          selected={selected}
        />
      </div>
    </Modal>
  );
};

export default PickLocationModal;

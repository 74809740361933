import { ORDER_STATUS } from "src/constants";

export interface PageParams {
  page?: number;
  limit?: number;
  paginationMetadataStyle?: "body";
}
export interface PageQueryParams {
  page: number;
  perPage: number;
  paginationMetadataStyle: "body";
  [key: string]: string | number;
}

export interface CategoryParams extends PageParams {
  direction?: "ASC" | "DESC";
  search?: string;
  sortBy?: string;
  isShowed?: boolean;
  hasParent?: boolean;
  parentId?: number;
  isAuMap?: boolean;
  isHKMap?: boolean;
}

export interface AttributeParams extends PageParams {
  search_value?: string;
}

// user

export interface MerchantOnboardParams extends PageParams {
  company_name: string;
}

export interface MerchantParams extends PageParams {
  name: string;
}

export interface StoreParams extends PageParams {
  keyword?: string;
  category_ids?: Array<number>;
  sort_by?: string;
  order_by?: "ASC" | "DESC";
  is_featured?: boolean;
}
export interface OrdersParams extends PageParams {
  search_value?: string;
  status?: ORDER_STATUS;
}

export interface FiatParams {
  start_date: string;
  end_date: string;
  reportView: "weekly" | "monthly" | "yearly";
}

export interface ProductListParams {
  limit?: number;
  page?: number;
  search?: string;
  status?: number;
  tierType?: number;
  direction?: "ASC" | "DESC";
  categoryId?: number | null;
  groupId?: string | number | null;
  sortBy?: string;
  hasParent?: boolean;
}

export enum EChartUnit {
  DAY = "day",
  WEEK = "week",
  MONTH = "month",
  YEAR = "year",
}

export interface TxChartForHomePageParams {
  startTime: string;
  endTime: string;
  unit: EChartUnit;
}

export interface GenderChartForHomePageParams extends TxChartForHomePageParams {}

export interface GenderChartByCategoryParams extends TxChartForHomePageParams {}

import { Button, ButtonProps } from "antd";
import React from "react";
import "./styles/button-contained.scss";

interface IButtonContainedProps extends ButtonProps {
  className?: "filter-purple" | "contained-secondary" | "btn-dark" | string;
}

const ButtonContained: React.FC<IButtonContainedProps> = ({
  children,
  className,
  ...props
}: IButtonContainedProps) => {
  return (
    <Button
      className={`contained-default ${className || className}`}
      {...props}
      // onMouseDown={(e) => e.preventDefault()}
    >
      {children}
    </Button>
  );
};

export default ButtonContained;

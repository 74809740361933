import { Table, TableProps } from "antd";
import { NoData } from "src/assets/icons";
import "./styles.scss";

export interface SearchTableProps extends TableProps<any> {
  totalItems: number;
  pageSize?: number;
  current?: number;
  loading?: boolean;
  handleClickRow?: (record: any, rowIndex?: number, event?: any) => void;
}

const SearchTable = (props: SearchTableProps) => {
  const { className, totalItems, current, pageSize, handleClickRow, loading } = props;
  
  return (
    <Table
      {...props}
      onRow={(record, rowIndex) => ({
        onClick: (event) => handleClickRow && handleClickRow(record, rowIndex, event),
      })}
      className={`search-table-default ${className ? className : null}`}
      pagination={
        totalItems
          ? {
              current,
              pageSize,
              total: totalItems,
              // showTotal: (total: number, range: number[]) =>
              //   `${range[0]}-${range[1]} of ${total} items`,
              showSizeChanger: false,
            }
          : false
      }
      loading={loading}
      locale={{
        emptyText: () => {
          return (
            <div className="no-data">
              <img src={NoData} alt=""/>
              <span>There are no records to be displayed</span>
            </div>
          );
        },
      }}
    />
  );
};

export default SearchTable;

import { ProductByQrCode } from "src/types/qr-code";
import { ProductListResponse } from "../types/Product";
import { ServiceBase } from "./core/service-base";
import { ProductListParams } from "./params-type";

export class ProductManagementService extends ServiceBase {
  getListProduct = async (params: ProductListParams): Promise<ProductListResponse> => {
    const res = await this.get(`/products`, params);
    return res.data;
  };

  getProductByQrCode = async (id: string): Promise<ProductByQrCode> => {
    const res = await this.get(`/products/by-qr-code/${id}`);
    return res.data;
  };

  getProductDetail = async (id: number | string): Promise<Product> => {
    const res = await this.get(`/products/${id}`);
    return res.data;
  };

  createProduct = async (payload: any) => {
    const response = await this.post("/products/create-product", payload);
    return response.data;
  };

  updateTraceability = async (productId: number | string, params: any) => {
    const res = await this.put(`/traceability-jouneys/admin/traceability/${productId}`, params);
    return res.data;
  };

  updateStatusProduct = async (productId: number | string) => {
    const res = await this.put(`/products/update-status/${productId}`);
    return res.data;
  };

  deleteQrCodeAdmin = async (ids: any) => {
    const res = await this.delete2("/qr-codes/admin", ids);
    return res.data;
  };
}

export const productManagementService = new ProductManagementService();

import { LoadingOutlined } from "@ant-design/icons";
import { Modal, Spin } from "antd";
import './styles.scss';
import { LoadingSpinner } from "src/assets/icons";

export const Spinner = ()=>{
  return (
    <Modal centered className="modal-loading" open={true} width="100vw" footer={null} closable={true}>
      <Spin indicator={<img src={LoadingSpinner}  alt="spinner"/>} className="spinner" />
    </Modal>
  );
}
import {
  Combobox,
  ComboboxInput,
  ComboboxList,
  ComboboxOption,
  ComboboxPopover,
} from "@reach/combobox";
import "@reach/combobox/styles.css";
import { Form } from "antd";
import { FormInstance } from "antd/es/form";
import React, { ChangeEvent } from "react";
import { handleValidatePickLocation } from "src/helpers/handleValidate";
import { getLatLngByAddress } from "src/helpers/maps";
import usePlacesAutocomplete from "use-places-autocomplete";
import { SelectedProps } from "../interface";
import "./styles.scss";

interface InputLocationProps {
  value?: string;
  isRequired?: boolean;
  label?: string;
  placeHolder?: string;
  disabled: boolean;
  name: any;
  form: FormInstance;
  index: number;
  setSelected: any;
  selected: SelectedProps[];
}
const InputLocation: React.FC<InputLocationProps> = (props) => {
  const {
    // isRequired = false,
    label = "Location",
    disabled = false,
    name,
    form,
    index,
    setSelected,
    selected,
  } = props;
  const {
    value,
    setValue,
    suggestions: { status, data },
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      componentRestrictions: {
        country: "HK",
      },
    },
  });

  const handleChangeReceiveLocation = async (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setValue(value);
    const data = form.getFieldValue("locations");
    data[index] = {
      ...data[index],
      location: value,
      longitude: null,
      latitude: null,
    };
    const formatDataSelected = data.map((item: any) => ({
      lat: null,
      lng: null,
    }));
    setSelected(formatDataSelected);
    form.setFieldValue("locations", data);
    const location = await getLatLngByAddress(value);
    if (!location) {
      const data = selected;
      selected[index] = {} as SelectedProps;
      setSelected(data);
    }
  };
  const handleSelectReceiveLocation = async (address: string) => {
    setValue(address, false);
    clearSuggestions();
    const location = await getLatLngByAddress(address);
    if (!location) {
      return;
    }

    const data = form.getFieldValue("locations");
    data[index] = {
      ...data[index],
      location: address,
      longitude: location.lng,
      latitude: location.lat,
    };
    const formatDataSelected = data.map((item: any) => ({
      lat: item?.latitude,
      lng: item?.longitude,
    }));
    setSelected(formatDataSelected);
    form.setFieldValue("locations", data);
    form?.validateFields([["locations", index, "location"]]);
  };

  return (
    <div className="location-category">
      <div className="location-category__input-location">
        <Combobox
          onSelect={(address) => handleSelectReceiveLocation(address)}
          className="location-category__input-location__search"
        >
          <Form.Item
            name={name}
            label={label}
            rules={[
              {
                validator: (_, value) => {
                  return handleValidatePickLocation(value, label);
                },
              },
            ]}
          >
            <ComboboxInput
              value={value ?? ""}
              onChange={(e) => handleChangeReceiveLocation(e)}
              placeholder="Enter address"
              disabled={disabled}
              className={!disabled ? "" : "combobox-input-disabled"}
            />
          </Form.Item>
          <ComboboxPopover className="combobox-popover">
            <ComboboxList>
              {status === "OK" && (
                <>
                  {data?.map(({ place_id, description }) => {
                    return (
                      <ComboboxOption
                        className="combobox"
                        key={place_id}
                        value={description}
                      ></ComboboxOption>
                    );
                  })}
                </>
              )}
            </ComboboxList>
          </ComboboxPopover>
        </Combobox>
      </div>
    </div>
  );
};

export default InputLocation;

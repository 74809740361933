import { Card, Col, Row } from "antd";
import { useEffect, useMemo, useState } from "react";
import InputSearch from "src/components/07.inputs/InputSearch";
import SearchTable from "src/components/11.tables/SearchTable";
import ColumnChart from "src/components/18.chart/ColumnChart";
import PieChart from "src/components/18.chart/PieChart";
import { productManagementService } from "src/services/product-management";
import "../styles.scss";

import { useParams } from "react-router";
import { toastMS } from "src/components/20.toast-message";
import { generateLast12MonthsForGenderChart, getTimeRangeForChart } from "src/helpers/chart";
import {
  useCountryChartByCategoryData,
  useGenderChartByCategoryData,
} from "src/hooks/useFetchChart";
import { EChartUnit, ProductListParams } from "src/services/params-type";

const CategoryDetail = () => {
  const [totalItems, setTotalItems] = useState(0);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [filteredValue, setFilteredValue] = useState(null);
  const [products, setProducts] = useState<ProductManagement[]>([]);
  const [searchProduct, setSearchProduct] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const LIMIT = 10;
  const { id } = useParams<{ id: string }>();
  const { data: countryData, loading: countryDataLoading } = useCountryChartByCategoryData(
    Number(id),
  );

  const { data: genderData, loading: genderDataLoading } = useGenderChartByCategoryData(
    {
      startTime: getTimeRangeForChart().startTime,
      endTime: getTimeRangeForChart().endTime,
      unit: EChartUnit.MONTH,
    },
    Number(id),
  );

  const countryChartData = useMemo(
    () =>
      countryData &&
      countryData?.map((data) => ({
        value: Number(data.percentage),
        type: data.countryName,
        count:Number(data.count),
      })),
    [countryData],
  );

  const txGenderData = useMemo(
    () => genderData && generateLast12MonthsForGenderChart(genderData),
    [genderData],
  );

  const totalScanByCountry = useMemo(
    () => countryData && countryData.reduce((sum, country) => sum + parseInt(country.count, 10), 0),
    [countryData],
  );

  const totalScanByGender = useMemo(
    () => txGenderData && txGenderData.reduce((sum, country) => sum + country.value, 0),
    [txGenderData],
  );

  // console.log('txGenderData',txGenderData);
  // console.log('totalScanByGender',totalScanByGender);
  // console.log('genderData',genderData);
  

  const fetchProducts = async () => {
        try {
      const params: ProductListParams = {
        page: currentPage,
        limit: LIMIT,
        search: searchProduct,
        categoryId: +id,
        direction:'DESC',
        sortBy:'createdAt'
      };
      if (filteredValue) {
        if (filteredValue !== "0") {
          params.hasParent = filteredValue === "2";
        }
      }

      // if (sortedInfo?.order) {
      //   params.sortBy = sortedInfo.columnKey?.toString();
      //   params.direction = sortedInfo?.order === "ascend" ? "ASC" : "DESC";
      // }
      const result = await productManagementService.getListProduct(params);
      setProducts(result.data);
      setTotalItems(result.meta.total);
    } catch (error) {
      console.log({ error });
      toastMS("error", "Error");
    }
  };

  useEffect(() => {
    fetchProducts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchProduct, currentPage, filteredValue]);

  const handleChange = (_pagination: any, filters: any, sorter: any) => {
    // setSortedInfo(sorter);
    setCurrentPage(_pagination.current);
  };

  const columns:any = [
    {
      title: "Product ID",
      dataIndex: "id",
      key: "id",
      // sorter: (a: any, b: any) => a.id - b.id,
      // sortOrder: sortedInfo.columnKey === "id" ? sortedInfo.order : null,
      render: (text: string, record: any) => <>{text}</>,
    },
    {
      title: "Product Name",
      dataIndex: "name",
      key: "name",
      // sorter: (a: any, b: any) => a.name - b.name,
      // sortOrder: sortedInfo.columnKey === "name" ? sortedInfo.order : null,
      render: (text: string, record: any) => <>{text}</>,
    },
    {
      title: "Linked Product ID",
      dataIndex: "parentId",
      key: "link",
      // sorter: (a: any, b: any) => a.parentId - b.parentId,
      // sortOrder: sortedInfo.columnKey === "link" ? sortedInfo.order : null,
      render: (text: string, record: any) => <>{record.parentId || "None"}</>,
    },
    {
      title: "Owner",
      dataIndex: "qrCode",
      key: "owner",
      // sorter: (a: any, b: any) => a.group?.name - b.group?.name,
      // sortOrder: sortedInfo.columnKey === "owner" ? sortedInfo.order : null,
      render: (qrCode: any, record: any) => <>{qrCode?.group?.name}</>,
    },
  ];

  const onSearch = (event: any) => {
    setSearchProduct(event.target.value);
    setCurrentPage(1);
  };
  return (
    <Row gutter={[16, 24]}>
      <Col span={24}>
        <Row gutter={[16, 24]}>
          <Col span={6}>
            <Card
              className="chart-card"
              title={
                <>
                  <h4 className="chart-title">Scans by Countries</h4>
                  <span className="chart-amount">{totalScanByCountry?.toLocaleString()}</span>
                </>
              }
            >
              <PieChart data={countryChartData} chartLoading={countryDataLoading} />
            </Card>
          </Col>
          <Col span={18}>
            <Card
              className="chart-card"
              title={
                <>
                  <h3 className="chart-title">Scans over 12 months by genders</h3>
                  <span className="chart-sub-title">
                    {totalScanByGender?.toLocaleString()} Scans in past 12 months
                  </span>
                </>
              }
            >
              <ColumnChart data={txGenderData} chartLoading={genderDataLoading} />
            </Card>
          </Col>
        </Row>
      </Col>
      <Col span={24}>
        <Card
          title={
            <Row align="middle">
              <Col span={10}>
                <InputSearch
                  placeholder="Search by name"
                  handlechangesearch={onSearch}
                  debouncetime={800}
                  enterbutton="Search"
                />
              </Col>
            </Row>
          }
        >
          <SearchTable
            totalItems={totalItems}
            columns={columns}
            dataSource={products}
            onChange={handleChange}
            pageSize={LIMIT}
            current={currentPage}
          />
        </Card>
      </Col>
    </Row>
  );
};

export default CategoryDetail;

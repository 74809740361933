import "antd/dist/antd.css";
import BigNumber from "bignumber.js";
import React, { useEffect, useState } from "react";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import "src/assets/scss/variable.scss";
import "src/assets/scss/_themes.scss";
import "./App.scss";
import Layout from "./components/01.layout";
import LocaleProviderComponent from "./components/15.locale-provider";
import ToastContext from "./contexts/toast";
import Routes from "./routes/Routes";
import initStore from "./store";
import { handleRefreshToken } from "./services/core/http-service";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { MsalProvider } from "@azure/msal-react";
import { LogLevel, PublicClientApplication } from "@azure/msal-browser";
import {
  createSessionCookie,
  getCookie,
  getStorageRememberMe,
  removeStorageJwtToken,
  removeStorageRefreshToken,
} from "./helpers/storage";
import { CLIENT_ID_GOOGLE } from "./constants/env";
import { config_login_microsoft } from "./helpers/config-login";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { PersistGate } from "redux-persist/lib/integration/react";

BigNumber.config({
  ROUNDING_MODE: BigNumber.ROUND_DOWN,
  EXPONENTIAL_AT: [-50, 50],
});

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false,
    },
  },
});

const App: React.FC = () => {
  const { store, persistor } = initStore();
  const [mount, setMount] = useState(false);
  const msalInstance = new PublicClientApplication(config_login_microsoft);

  useEffect(() => {
    (async () => {
      if (getStorageRememberMe()) {
        await handleRefreshToken();
      } else {
        const isActive = getCookie("isActive");
        if (!isActive) {
          removeStorageJwtToken();
          removeStorageRefreshToken();
          // user is not logged in, redirect to login page
          createSessionCookie("isActive", "true");
          window.location.href = "/login";
        }
      }
      setMount(true);
    })();
  }, []);

  if (!mount) return null;

  return (
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <PersistGate persistor={persistor}>
          <LocaleProviderComponent>
            <MsalProvider instance={msalInstance}>
              <GoogleOAuthProvider clientId={CLIENT_ID_GOOGLE || ""}>
                <BrowserRouter basename="">
                  {/* <WrapperLayout /> */}
                  <ToastContext />
                  <Layout>
                    <Routes />
                  </Layout>
                </BrowserRouter>
              </GoogleOAuthProvider>
            </MsalProvider>
          </LocaleProviderComponent>
        </PersistGate>
      </QueryClientProvider>
    </Provider>
  );
};

export default App;

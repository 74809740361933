import { GoogleMap, Marker } from "@react-google-maps/api";
import _ from "lodash";
import { useEffect, useMemo, useState } from "react";
import { GoogleMapLocation } from "src/services/google-map-service";
import "./styles.scss";

interface SimpleMapProps {
  width?: string;
  height?: string;
  className?: string;
  handlePickLocation?: (lat: number, lng: number, result: any) => void;

  selected?: { lat: number; lng: number }[];
  selectedIndex?: number;
}

export default function SimpleMap(props: SimpleMapProps) {
  const { handlePickLocation, selected, selectedIndex } = props;
  const Hongkong = { lat: 22.3220806286703, lng: 114.17072932448195 };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const center = useMemo(() => Hongkong, []);
  const [maps, setMaps] = useState<any>();
  const getlocation = new GoogleMapLocation();

  if (!navigator.geolocation) {
    throw new Error("No geolocation available");
  }

  useEffect(() => {
    if (!selected || selectedIndex === undefined) return;
    if (isNaN(selected[selectedIndex]?.lat) || isNaN(selected[selectedIndex]?.lng)) {
      return;
    }
    if (maps) {
      maps.panTo({ lat: selected[selectedIndex].lat, lng: selected[selectedIndex].lng });
    }
  }, [selected, maps, selectedIndex]);

  return (
    <>
      <GoogleMap
        mapContainerStyle={{
          width: "100%",
          height: "100%",
        }}
        center={center}
        zoom={10}
        onLoad={(map: any) => {
          setMaps(map);
        }}
        options={{
          gestureHandling: "greedy",
          zoomControl: false,
          fullscreenControl: false,
          mapTypeControl: false,
          minZoom: 3,
          streetViewControl: false,
          rotateControl: false,
          scaleControl: false,
        }}
        onClick={async (e: any) => {
          const lat = e.latLng.lat();
          const lng = e.latLng.lng();
          const latLng = [lat, lng];
          const result = await getlocation.getLocation({ latlng: latLng.join(",") });
          handlePickLocation && handlePickLocation(lat, lng, result);
        }}
      >
        {!_.isEmpty(selected) &&
        selected &&
        selectedIndex !== undefined &&
        !_.isEmpty(selected[selectedIndex]) ? (
          <Marker position={selected[selectedIndex]} />
        ) : null}
      </GoogleMap>
    </>
  );
}

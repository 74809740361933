import { SearchOutlined } from '@ant-design/icons';
import { Input, InputProps } from 'antd';
import { debounce } from 'lodash';
import React, { useCallback } from 'react';
import './styles.scss';
const { Search } = Input;
interface IInputSearch extends InputProps {
  handlechangesearch: (e: React.ChangeEvent<HTMLInputElement>) => void;
  debouncetime: number;
  searchResultCount?: number;
  enterbutton?: any;
  className?: string;
}

const InputSearch: React.FC<IInputSearch> = (props) => {
  const { placeholder, handlechangesearch, debouncetime, enterbutton, className } = props;
  const handleDebounceChange = useCallback(debounce(handlechangesearch, debouncetime), [
    handlechangesearch,
  ]);

  const handleChangeInternal = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      handleDebounceChange(e);
    },
    [handleDebounceChange],
  );
  return (
    <>
      <Input
        size="large"
        allowClear
        suffix={<SearchOutlined style={{fontSize: '26px'}} />}
        placeholder={placeholder}
        onChange={handleChangeInternal}
        className={className ? className : "input-search"}
        {...props}
      />
      {/* <span className="input__result">
        Show{" "}
        <strong className="input__result--count">{searchResultCount}</strong>{" "}
        results
      </span> */}
    </>
  );
};

export default InputSearch;
